import React, { useState } from "react";
import "./Projects.scss";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import cbsLogin from "../../assets/final_login.gif";
import cbsProfile from "../../assets/final_profile.gif";
import cbsProfile2 from "../../assets/final_profile2.png";
import twitterChat from "../../assets/twitter_chat.png";
import twitterLogin from "../../assets/twitter_login.png";
import twitterMain from "../../assets/twitter_main.png";
import twitterProfile from "../../assets/twitter_profile.png";
import twitterWelcome from "../../assets/twitter_welcome.png";
import vendingmachineWelcome from "../../assets/vendingmachine_welcome.png";
import kgLanding from "../../assets/kg_landing.png";
import kgPhone from "../../assets/kg_phone.png";
import kgTablet from "../../assets/kg_tablet.png";
import kgText from "../../assets/kg_text.png";

function Projects() {
	const [show, setShow] = useState(false);
	const [showName, setShowName] = useState("");

	const handleClick = (e: any) => {
		setShow(true);
		setShowName(e.target.id);
	};

	const imageList = [
		{
			name: "kg",
			alt: "KG Aesthetic Lashes (2024~)",
			images: [kgLanding, kgText, kgTablet, kgPhone],
			contents: [
				"A website for KG Aesthetic Lashes.",
				"It is currently an on-going project.",
				"The first version of the website - Under Consturction - is released on April 3rd, 2024. The second version is scheduled to be released in May, 2024.",
				"You can visit the website ",
			],
			link: "https://kg-lashes.de",
		},
		{
			name: "vendingmachine",
			alt: "Getränkeliste Project (2022)",
			images: [vendingmachineWelcome],
			contents: [
				"Between 2021 and 2022, I have completed a 6 month internship (praktikum) at XIBIX, my current employer.",
				"As part of my Bachelor's thesis, I have learned Angular and participated in creating the Getränkeliste website.",
				"If you are interested, you can checkout my thesis repositry and documentation ",
			],
			link: "https://gitlab.com/HyunJi-Clara-Lee/vending-machine-project",
		},
		{
			name: "twitter",
			alt: "Twitter Copy Project (2021)",
			images: [
				twitterWelcome,
				twitterLogin,
				twitterMain,
				twitterChat,
				twitterProfile,
			],
			contents: [
				"The Twitter Copy Project was created for the Database and Web Development courses at Copenhagen School of Design and Technology (KEA), in 2021.",
				"The primary focus of the project was to recreate the frontend of the Twitter website using PHP and JavaScript.",
			],
			link: "",
		},
		{
			name: "cbs",
			alt: "CBS Students App (2021)",
			images: [cbsLogin, cbsProfile, cbsProfile2],
			contents: [
				"The CBS Students App was created for the Mobile Platforms course at Copenhagen School of Design and Technology (KEA), in 2021.",
				"",
			],
			link: "",
		},
	];

	let renderText = "";
	const renderImages = imageList.map((item) => {
		if (item.name === showName) {
			renderText = item.alt;
			let render = item.images.map((img: string) => {
				return (
					<Carousel.Item interval={10000}>
						<img src={img} alt={item.alt} />
					</Carousel.Item>
				);
			});
			return (
				<Carousel slide={false} controls={true}>
					{render == null ? (
						<Carousel.Item interval={10000}>
							<img src="" alt="" />
						</Carousel.Item>
					) : (
						render
					)}
				</Carousel>
			);
		} else {
			return <div></div>;
		}
	});

	const renderContents = imageList.map((item) => {
		if (item.name === showName) {
			return (
				<div className="fontText">
					{item.contents.map((text, index) => {
						return (
							<>
								{index > 0 ? (
									<>
										<br />
										<br />
									</>
								) : (
									<></>
								)}
								{text}
							</>
						);
					})}
					{item.link !== "" ? (
						<a href={item.link} target="_blank" rel="noopener noreferrer">
							here.
						</a>
					) : (
						<></>
					)}
				</div>
			);
		} else {
			return <div></div>;
		}
	});

	return (
		<section className="projectsContainer" id="projects">
			<div className="fontHeader">Projects</div>
			<div>
				<Carousel slide={false} controls={true}>
					<Carousel.Item interval={10000}>
						<img
							src={kgLanding}
							alt="KG Aesthetic Lashes"
							id="kg"
							onClick={handleClick}
						/>
						<Carousel.Caption className="fontText fontDark">
							<h3 id="kg" onClick={handleClick}>
								KG Aesthetic Lashes (2024~)
							</h3>
							<div className="fontText" id="kg" onClick={handleClick}>
								A website for KG Aesthetic Lashes. It is an on-going project.
								<br />
								<strong>Click here or the image for more information.</strong>
							</div>
						</Carousel.Caption>
					</Carousel.Item>
					<Carousel.Item interval={10000}>
						<img
							src={vendingmachineWelcome}
							alt="Getränkeliste Project"
							id="vendingmachine"
							onClick={handleClick}
						/>
						<Carousel.Caption className="fontText fontDark">
							<h3 id="vendingmachine" onClick={handleClick}>
								Getränkeliste Project (2022)
							</h3>
							<div
								className="fontText"
								id="vendingmachine"
								onClick={handleClick}>
								An Angular website built as an internal project during the
								internship at XIBIX.
								<br />
								<strong>Click here or the image for more information.</strong>
							</div>
						</Carousel.Caption>
					</Carousel.Item>
					<Carousel.Item interval={10000}>
						<img
							src={twitterMain}
							alt="Twitter Copy Project"
							id="twitter"
							onClick={handleClick}
						/>
						<Carousel.Caption className="fontText fontDark">
							<h3 id="twitter" onClick={handleClick}>
								Twitter Copy Project (2021)
							</h3>
							<div className="fontText" id="twitter" onClick={handleClick}>
								A Twitter frontend copy project built for courses at KEA.
								<br />
								<strong>Click here or the image for more information.</strong>
							</div>
						</Carousel.Caption>
					</Carousel.Item>
					<Carousel.Item interval={10000}>
						<img
							src={cbsProfile}
							alt="CBS Students App"
							id="cbs"
							onClick={handleClick}
						/>
						<Carousel.Caption className="fontText fontDark">
							<h3 id="cbs" onClick={handleClick}>
								CBS Students App (2021)
							</h3>
							<div className="fontText" id="cbs" onClick={handleClick}>
								A React Native mobile app project built for courses at KEA.
								<br />
								<strong>Click here or the image for more information.</strong>
							</div>
						</Carousel.Caption>
					</Carousel.Item>
				</Carousel>
			</div>
			<div>
				<Modal
					show={show}
					size="xl"
					centered
					onHide={() => setShow(false)}
					aria-labelledby="modal-title">
					<Modal.Header closeButton>
						<Modal.Title className="modal-title fontHeader" id="modal-title">
							{renderText}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<Container>
							<Row>
								<div className="modalImage">{renderImages}</div>
								<div className="modalText">{renderContents}</div>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
			</div>
		</section>
	);
}

export default Projects;
