import React from "react";
import "./Welcome.scss";
import AnimationText from "../../components/AnimationText/AnimationText";

function Welcome() {
	return (
		<div className="welcomeContainer fontWelcome" id="welcome">
			<div className="welcomeAnimationContainer">
				<AnimationText
					slideDirection="right"
					text="Frontend Developer"></AnimationText>
				<AnimationText
					slideDirection="left"
					text="Hyun Ji Clara Lee"></AnimationText>
				<AnimationText
					slideDirection="right"
					text="Frontend Developer"></AnimationText>
				<AnimationText
					slideDirection="left"
					text="Hyun Ji Clara Lee"></AnimationText>
			</div>
			<div className="welcomeTextContainer">
				Hello World! <br />I am <span className="highlight">Hyun Ji Lee</span>,{" "}
				<br />a Front-End Developer.
			</div>
		</div>
	);
}

export default Welcome;
