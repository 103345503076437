import React, { useEffect, useState } from "react";
import { type AnimationTextProps } from "@/lib/types";
import "./AnimationText.scss";

function AnimationText({
	slideDirection,
	text,
}: AnimationTextProps) {
	const [scrollDirection, setScrollDirection] = useState<"up" | "down">("up");
	const [scrollPosition, setScrollPosition] = useState<number>(0);
	const [scrollTop, setScrollTop] = useState<number>(0);
	const calc: number = slideDirection === "right" ? 1 : -1;

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			setScrollTop(scrollTop);

			if (scrollTop > scrollPosition) {
				setScrollDirection("down");
			} else {
				setScrollDirection("up");
			}

			setScrollPosition(scrollTop);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollPosition]);

	return (
		<>
			<div
				className="animationText"
				style={{
					transform: `translateX(${
						scrollDirection === "down"
							? calc * (scrollTop - scrollPosition / 10)
							: calc * (scrollPosition - scrollTop / 10)
					}px)`,
				}}>
				{text}
			</div>
		</>
	);
}

export default AnimationText;
