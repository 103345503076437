import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.scss";

function Footer({ className }: { className: string }) {
	const year = new Date().getFullYear();
	return (
		<div className={`footerContainer ${className}`}>
			<div className="footerRow">
				<div className="footerItem">
					<div className="footerText">&copy;{year} Hyun Ji Clara Lee</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;
