import React from "react";
import profile from "../../assets/profile.jpeg";
import "./AboutMe.scss";

function AboutMe() {
	const text = "HYUN JI LEE - FRONTEND DEVELOPER -";
	const textArray = [
		"H",
		"Y",
		"U",
		"N",
		" ",
		"J",
		"I",
		" ",
		"L",
		"E",
		"E",
		" ",
		" ",
		" ",
		"F",
		"R",
		"O",
		"N",
		"T",
		"E",
		"N",
		"D",
		" ",
		"D",
		"E",
		"V",
		"E",
		"L",
		"O",
		"P",
		"E",
		"R",
		" ",
		" ",
		" ",
	];

	const imageRender = textArray.map((char, index) => {
		return (
			<span
				style={{
					transform: `rotate(${(360 / textArray.length) * index}deg)`,
				}}>
				{char}
			</span>
		);
	});

	return (
		<section className="aboutMeContainer" id="about-me">
			<div className="aboutMeImageContainer">
				<img src={profile} alt="Hyun Ji Lee" className="center-image" />
				{/* <div className="imageTextDecoration fontHighlight">{imageRender}</div> */}
			</div>
			<div className="aboutMeContentsContainer">
				<div className="aboutMeContentsHeader fontHeader">About Me</div>
				<div className="aboutMeContentsText fontText">
					Hi there!
					<br /> <br />
					My name is Hyun Ji Lee, a dynamic Web Developer with more than two
					years of experience, specializing in JavaScript.
					<br /> <br />
					I'm originally from South Korea, but I am currently located in
					Germany, working at a german cloud company.
					<br /> <br />
					I have participated in multiple projects using variety of
					technologies: JavaScript frameworks (Node.js, jQuery, React, React
					Native, Angular), CSS (SASS, Bootstrap), Webpack, PHP, Google
					Firebase, and Azure.
					<br /> <br />I am also familiar with other tools such as GitHub,
					GitLab, Azure DevOps, Jira, and Figma.
					<br /> <br />I am dedicated to continuous improvement and the delivery
					of efficient, high-quality digital solutions. With a strong ability to
					solve problems and learn quickly, I excel in fast-paced tech
					environments, consistently ensuring adaptability and excellence.
				</div>
			</div>
		</section>
	);
}

export default AboutMe;
