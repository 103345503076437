import React from "react";
import "./Contacts.scss";
import Footer from "../../components/Footer/Footer";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { HiDocumentArrowDown } from "react-icons/hi2";
import resume from "../../assets/resume.pdf";
import FlipText from "../../components/FlipText/FlipText";

function Contacts({ section }: { section: String }) {
	console.log(section);
	return (
		<section className="contactsContainer">
			<div className="contactsContentContainer container" id="contacts">
				<div className="contactsHeader fontHighlight">
					Contacts?
					<br />
					It's all here =)
				</div>
				<div className="contactsTable fontHighlight fontPink">
					<div className="contactsTableCell">
						<a
							href="https://github.com/ClaraLee95"
							target="_blank"
							rel="noopener noreferrer">
							<FlipText
								originalText="GitHub"
								flippedText="github.com/ClaraLee95">
								<AiFillGithub
									size={45}
									style={{
										color: "#ec3c8a",
									}}
								/>
							</FlipText>
						</a>
					</div>
					<div className="contactsTableCell">
						<a
							href="https://www.linkedin.com/in/hyunji-clara-lee/"
							target="_blank"
							rel="noopener noreferrer">
							<FlipText
								originalText="LinkedIn"
								flippedText="linkedin.com/in/hyunji-clara-lee">
								<AiFillLinkedin
									size={45}
									style={{
										color: "#ec3c8a",
									}}
								/>
							</FlipText>
						</a>
					</div>
					<div className="contactsTableCell">
						<a
							href="mailto:clara.klaerner@gmail.com"
							target="_blank"
							rel="noopener noreferrer">
							<FlipText
								originalText="Email"
								flippedText="clara.klaerner@gmail.com">
								<MdEmail
									size={45}
									style={{
										color: "#ec3c8a",
									}}
								/>
							</FlipText>
						</a>
					</div>
					<div className="contactsTableCell">
						<div
							id="resume"
							className={section === "#resume" ? "blink shake" : ""}>
							<a href={resume} download="Resume - Hyun Ji Lee">
								<FlipText originalText="Resume" flippedText="Download Resume">
									<HiDocumentArrowDown
										size={45}
										style={{
											color: "#ec3c8a",
										}}
									/>
								</FlipText>
							</a>
						</div>
					</div>
				</div>
			</div>
			<Footer className="fontFooter"></Footer>
		</section>
	);
}

export default Contacts;
