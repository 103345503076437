import React, { useState } from "react";
import { type FlipTextProps } from "@/lib/types";
import "./FlipText.scss";

function FlipText({ originalText, flippedText, children }: FlipTextProps) {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div
			className={`flipContainer ${isHovered ? "hovered" : ""}`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}>
			{children}
			<div className="flipTextContainer">
				<div className="front">{originalText}</div>
				<div className="back">{flippedText}</div>
			</div>
		</div>
	);
}

export default FlipText;
