import React from "react";
import NavBar from "./components/NavBar/Navbar";
import Welcome from "./views/Welcome/Welcome";
import AboutMe from "./views/AboutMe/AboutMe";
import Projects from "./views/Projects/Projects";
import Contacts from "./views/Contacts/Contacts";
import "./App.scss";
import { useLocation } from "react-router-dom";

function App() {
	let bodyClass = "backgroundLight fontDark";
	const location = useLocation().hash;

	return (
		<div className={`pageContainer ${bodyClass}`}>
			<NavBar className="fontMenu" />
			<div className="contentsContainer">
				<Welcome></Welcome>
				<div className="container">
					<AboutMe></AboutMe>
					<Projects></Projects>
					<Contacts section={location}></Contacts>
					{/* <Routes>
					<Route path="/" element={<Home></Home>}></Route>
					<Route path="uber-mich" element={<AboutMe></AboutMe>}></Route>
				</Routes> */}
				</div>
			</div>
		</div>
	);
}

export default App;
