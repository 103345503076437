import React, { useState, useEffect } from "react";
import logo from "../../assets/logo_full.png";
import "./NavBar.scss";
import NavBarItem from "../NavBarItem/NavBarItem";

function NavBar({ className }: { className: string }) {
	const [showPopupBar, setShowPopupBar] = useState<boolean>(false);

	useEffect(() => {
		const handleScroll = () => {
			// Handling Nav Bar
			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			if (scrollTop > 0 && scrollTop > 72) {
				setShowPopupBar(true);
			} else {
				setShowPopupBar(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<nav className={`fontMenu navBar-fixed ${showPopupBar ? "hide" : ""}`}>
				<div className="menuContainer">
					<NavBarItem id="about-me">About Me</NavBarItem>
					<NavBarItem id="projects">Projects</NavBarItem>
					<NavBarItem id="contacts">Contacts</NavBarItem>
					<NavBarItem id="resume">Resume</NavBarItem>
				</div>
			</nav>
			<nav className={`fontMenu navBar-popup ${showPopupBar ? "show" : ""}`}>
				<div className="logoContainer">
					<a href="/#welcome">
						<img className="logo" src={logo} alt="Hyun Ji Lee" />
					</a>
				</div>
				<div className="menuContainer">
					<NavBarItem id="about-me">About Me</NavBarItem>
					<NavBarItem id="projects">Projects</NavBarItem>
					<NavBarItem id="contacts">Contacts</NavBarItem>
					<NavBarItem id="resume">Resume</NavBarItem>
				</div>
			</nav>
		</>
	);
}

export default NavBar;
